<script>
import { mapGetters } from "vuex";
import imageSwapper from "@/components/image-swapper.vue";
export default {
  name: "entry",
  components: {
    imageSwapper,
  },
  props: {
    entry: {},
    searchTrade: {
      default: null,
      type: String,
    },
    to: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      isHovering: false,
      trades: {
        ltg_for_se: "Venta",
        ltg_for_sf: "Venta Amueblado",
        ltg_for_rde: "Renta Diaria",
        ltg_for_rdf: "Renta Amueblado Diaria",
        ltg_for_rme: "Renta Mensual",
        ltg_for_rmf: "Renta Amueblado Mensual",
      },
    };
  },
  computed: {
    ...mapGetters("config", ["get_category", "get_location"]),
    category() {
      return this.get_category(this.entry.ltg_cat);
    },
    location() {
      return this.get_location(this.entry.ltg_lctn);
    },
    _target() {
      return !this.$sitio.isMobile &&
        this.$store.getters["settings/getSetting"]("new_tab_for_listings")
        ? "_blank"
        : "_self";
    },
    route() {
      if (this.to) return this.to;
      return this.$router.resolve({
        name: "finder-single",
        params: {
          ltg_id: this.entry.ltg_id,
        },
        query: {
          variant: this.searchTrade,
        },
      });
    },
  },
};
</script>

<template>
  <router-link
    :to="route.href"
    :id="'entry-' + entry.ltg_id"
    :target="_target"
    class="entry"
    :class="{ hovering: isHovering }"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
  >
    <div class="wrapper">
      <imageSwapper
        class="gallery"
        :images="entry.ltg_images"
        sizes="(min-width: 100em) 16.666666667vw, (min-width: 75em) 20vw, (min-width: 60em) 25vw, (min-width: 46em) 30vw, (min-width: 40em) 40vw, 95vw"
      />
      <div class="entry__content">
        <div class="entry-category">{{ category.label }}</div>
        <div class="entry-title">{{ location.loc_name_short }}</div>
        <div class="prices">
          <div v-if="searchTrade" class="price">
            <div class="price__value">
              ${{ entry[searchTrade] | formatMoney(0) }}
            </div>
            <div class="price__currency">USD</div>
          </div>
          <template v-else v-for="(label, trade) in trades">
            <div v-if="entry[trade]" :key="trade" class="price">
              <div class="price__label">{{ label }}:</div>
              <div class="price__value">
                ${{ entry[trade] | formatMoney(0) }}
              </div>
              <div class="price__currency">USD</div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </router-link>
</template>
