<script>
export default {
  props: {
    preload: {
      type: Boolean,
      default: false,
    },
    sizes: {
      type: String,
      default: "",
    },
    images: {
      type: Array,
      default() {
        return [];
      },
    },
    proportions: {
      default: "landscape",
      type: String,
    },
    maxBullets: {
      default: 4,
      type: Number,
    },
    bulletsRange: {
      default: 3,
      type: Number,
    },
  },

  data() {
    return {
      current: 0,
      hasChanged: false,
    };
  },

  computed: {
    indicators() {
      if (!this.computedImages.length) return null;
      let indicators = [];
      let total = this.computedImages.length;
      let top = this.maxBullets < total ? this.maxBullets : total;
      let range = this.bulletsRange;
      indicators.push(this.current);
      // console.log("push current", this.current);
      let count_prev = 0;
      for (let i = this.current - 1; count_prev < range; i--) {
        if (i < 0) break;
        // console.log("push prev", i);
        indicators.push(i);
        count_prev++;
      }
      let count_next = 0;
      for (let i = this.current + 1; count_next < range; i++) {
        if (i >= total) break;
        // console.log("push next", i);
        indicators.push(i);
        count_next++;
      }
      let count_now = indicators.length;
      if (count_now < top) {
        let missing = top - count_now;
        if (count_next < range) {
          for (let i = Math.min(...indicators) - 1; missing > 0; i--) {
            if (i < 0) break;
            // console.log("push extra prev", i);
            indicators.push(i);
            missing--;
          }
        }
        if (count_prev < range && Math.max(...indicators) != 0) {
          for (var i = Math.max(...indicators) + 1; missing > 0; i++) {
            if (i >= total) break;
            // console.log("push extra next", i);
            indicators.push(i);
            missing--;
          }
        }
      }
      indicators.sort(function(a, b) {
        return a - b;
      });
      return indicators;
    },
    shouldPreload() {
      return this.preload || this.hasChanged;
    },
    computedImages() {
      if (!this.images.length) return [];
      let images = this.images;
      return images;
    },
    currentImage() {
      return this.computedImages[this.current];
    },
    image() {
      let img = new Image();
      img.src = this.computedImages[this.current].src;
      img.srcset = this.computedImages[this.current].srcset;
      img.sizes = this.sizes;
      return img;
    },
  },

  methods: {
    nextImg() {
      // if (this.computedImages[this.current + 1]) this.current++;
      // else this.current = 0;
      if (this.current + 1 > this.computedImages.length - 1) {
        this.current = 0;
      } else {
        this.current++;
      }
    },
    prevImg() {
      // if (this.computedImages[this.current - 1]) this.current--;
      // else this.current = this.computedImages.length - 1;
      if (this.current - 1 < 0) {
        this.current = this.computedImages.length - 1;
      } else {
        this.current--;
      }
    },
    update() {
      let img = new Image();
      img.srcset = this.computedImages[this.current].srcset;
      img.sizes = this.sizes;
      img.src = this.computedImages[this.current].src;
      // img.className = "current";
      this.$el.getElementsByClassName("image-container")[0].appendChild(img);
    },
    swipeHandler(direction) {
      if (!this.computedImages.length) return;
      if (direction == "left") this.nextImg();
      else if (direction == "right") this.prevImg();
    },
    classDot(value) {
      // if (value == this.current)
      //   return "big";
      // else
      //   return "small";
      switch (value) {
        case this.current:
          return "big";
        case this.current + 1:
        case this.current - 1:
        case this.current + 2:
        case this.current - 2:
          return "medium";
        default:
          return "small";
      }
    },
  },

  watch: {
    current() {
      if (!this.hasChanged) this.hasChanged = true;
      // this.update();
      // let container = this.$el.getElementsByClassName("image-container")[0];
      // console.log(this.$el.getElementsByClassName("image-container"));
      // console.log(this.image);
      // console.log(this.$el);
    },
  },
};
</script>

<template>
  <div
    v-if="images.length"
    class="imageSwapper"
    :class="{ swipable: images.length > 1 }"
    v-touch:swipe="swipeHandler"
  >
    <svg class="size-holder" viewBox="0 0 2 2" v-if="proportions == 'square'" />
    <svg class="size-holder" viewBox="0 0 3 2" v-else />
    <!-- <img
      v-if="proportions == 'square'"
      class="size-holder"
      src="/img/holders/sizeholder-square.png"
    /> -->
    <!-- <img
      v-else
      class="size-holder"
      src="/img/holders/sizeholder-landscape.png"
    /> -->

    <div
      v-for="(image, index) in images"
      :key="index"
      class="image-container"
      :class="{ current: index == current }"
    >
      <img
        v-if="index == current || shouldPreload"
        :srcset="image.srcset"
        :sizes="sizes"
        :src="image.src"
      />
    </div>
    <div class="slider-controls" v-if="images.length > 1">
      <b-button
        icon-left="chevron-left"
        class="prev"
        @click.prevent.stop="prevImg()"
      ></b-button>
      <b-button
        icon-right="chevron-right"
        class="next"
        @click.prevent.stop="nextImg()"
      ></b-button>
    </div>
    <div class="indicators" v-if="images.length > 1">
      <transition-group class="wrapper" v-if="indicators" tag="div">
        <span
          class="dot"
          v-for="dot in indicators"
          :key="dot"
          :class="classDot(dot)"
        />
      </transition-group>
    </div>
  </div>
  <div v-else class="imageSwapper noImages">
    <img
      v-if="proportions == 'square'"
      class="size-holder"
      src="/img/holders/sizeholder-square.png"
    />
    <img
      v-else
      class="size-holder"
      src="/img/holders/sizeholder-landscape.png"
    />
  </div>
</template>

<style lang="scss">
.imageSwapper {
  position: relative;
  background-color: #e8e8e8;
  // &.swipable {
  //   cursor: grab;
  // }
  &:active {
    cursor: grabbing;
  }
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  svg,
  img {
    display: block;
    width: 100%;
    max-width: 100%;
  }
  .image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    // background-color: #ccc;
    overflow: hidden;
    transition: opacity 0.5s;
    &:not(.current) {
      opacity: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
  }

  .slider-controls {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      // height: 2rem;
      font-size: 1.5rem;
      display: block;
      background-color: transparent;
      border: none;
      color: #fff;
      height: 100%;
      width: 20%;
      padding: 0;
      box-shadow: none !important;
      position: relative;
      border-radius: 0;
      .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      &.prev {
        background: linear-gradient(
          to left,
          transparent 0%,
          rgba(0, 0, 0, 0.25) 100%
        );
        .icon {
          left: 1rem;
        }
      }
      &.next {
        background: linear-gradient(
          to right,
          transparent 0%,
          rgba(0, 0, 0, 0.25) 100%
        );
        .icon {
          right: 1rem;
        }
      }
    }
  }

  .indicators {
    position: absolute;
    width: 100%;
    bottom: 0px;
    padding: 0.5rem 0;
    z-index: 1;
    font-size: 0.5rem;
    background: linear-gradient(
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.3) 100%
    ) !important;
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      contain: content;
      height: 1rem;
      .dot {
        font-size: 0.5em;
        position: relative;
        width: 1em;
        height: 1em;
        opacity: 0.5;
        margin: 0 0.25rem;
        transition: all 250ms;
        transform-origin: 50% 50%;
        transform: scale(1);
        &::after {
          background-color: #fff;
          border-radius: 100%;
          position: absolute;
          display: block;
          content: "";
          width: 100%;
          height: 100%;
        }
      }
      .big {
        opacity: 1;
        font-size: 1em;
      }
      .medium {
        opacity: 0.75;
        font-size: 0.75em;
      }
      .v-leave-active {
        position: absolute;
        font-size: 0rem;
      }
      .v-enter,
      .v-leave-to {
        opacity: 0;
        transform: scale(0);
      }
    }
  }
}
</style>
